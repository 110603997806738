import '../../App.css';


import { Features, Header, Preload, Promotion, WelcomeArea,Testimoni, Footer, PriceList } from '../../components';
import { useState } from 'react';

function Home() {

  const [Diskon,setDiskon] = useState(40);

  return (
    <div>
      {/* <Preload /> */}
      <Header />
      <WelcomeArea Diskon={Diskon}/>
      {/* <Features /> */}
      <div style={{height:150}}></div>
      <Promotion />
      <Testimoni />
      <PriceList Diskon={Diskon}/>
      <Footer />

    </div>
  );
}

export default Home;
