import { useState } from "react";
import { useHistory } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

const Header = () => {
    const history = useHistory();

    const [Nama, setNama] = useState("")
    const [Email, setEmail] = useState("")
    const [Pertanyaan, setPertanyaan] = useState("")
    const [ShowTextAlertValidasi, setShowTextAlertValidasi] = useState(false)
    const [TextAlertValidasi, setTextAlertValidasi] = useState("")


    const submitPertanyaan = () => {
        let validasiMessage ="";
        if (Nama == "") {
            validasiMessage = validasiMessage + "Silahkan isi nama anda terlebih dahulu.\n";
            setShowTextAlertValidasi(true)
            setTextAlertValidasi(validasiMessage)
            return false;
        } 
        if (Email == "") {
            validasiMessage = validasiMessage + "Silahkan isi email anda terlebih dahulu.\n";
            setShowTextAlertValidasi(true)
            setTextAlertValidasi(validasiMessage)
            return false;
        } 
        if (Pertanyaan == "") {
            validasiMessage = validasiMessage + "Silahkan isi pertanyaan terlebih dahulu.\n";
            setShowTextAlertValidasi(true)
            setTextAlertValidasi(validasiMessage)
            return false;
        } 

        if (validasiMessage != "") {
        //    alert(validasiMessage)
            setShowTextAlertValidasi(true)
            setTextAlertValidasi(validasiMessage)
            return false;
        }

        window.open("https://wa.me/6283866502154?text=Hallo nama saya "+Nama+", "+Pertanyaan)
        // var requestBody = JSON.stringify({
        //     "Nama":Nama,
        //     "EmailWA":Email,
        //     "Pertanyaan":Pertanyaan
        // });

        // var url = 'https://ulemi.id/api/SubmitPertanyaan.php';
       
        // // setIsLoading(true)
        // fetch(url, {
        //     method: "POST",
        //     body: requestBody,
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        // })
        // // .then(fetchStatus)
        // .then(response => response.json())
        // .then((data) => {
        //     // var errmsg = data.errmsg;
        //     // setIsLoading(false)
        //     if (data.ErrCode == "0") {
        //         // alert("Berhasil kirim pertanyaan")
                
        //     } else {
        //         alert("Gagal kirim pertanyaan")
        //     }
        // })
        // .catch((error) => {
        //     // setIsLoading(false)
        //     alert("Gagal kirim pertanyaan")
        // });
    }

    return (
        <footer id="contact-us">
            <div className="container">
                <div className="footer-content">

                    <div className="row">
                        {/* <!-- ***** Contact Form Start ***** --> */}
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <br />
                            <br />
                            <br />
                            <h2 style={{
                                color: 'white',
                                fontSize: 37,
                                fontWeight: 600
                            }}>Boleh tanya-tanya dulu</h2>
                            <div className="contact-form">
                            <SweetAlert
                                    show={ShowTextAlertValidasi}
                                    onConfirm={() => {
                                        setShowTextAlertValidasi(false)
                                        setTextAlertValidasi("")
                                    }}
                                    onEscapeKey={() => {
                                        setShowTextAlertValidasi(false)
                                        setTextAlertValidasi("")
                                    }}
                                    onOutsideClick={() => {
                                        setShowTextAlertValidasi(false)
                                        setTextAlertValidasi("")
                                    }}
                                    btnSize="sm"
                                >
                                    {() => (
                                        <div>
                                            <p style={{ fontSize: '20px' }}>{TextAlertValidasi}</p>
                                        </div>
                                    )}
                                </SweetAlert>
                                {/* <form id="contact" action="" method="post"> */}
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <fieldset>
                                                <input name="name" type="text" id="name" placeholder="Nama" required=""
                                                    style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                    value={Nama}
                                                    onChange={event => setNama(event.target.value)}
                                                />
                                            </fieldset>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <fieldset>
                                                <input name="email" type="text" id="email" placeholder="Email atau WhatsApp"
                                                    required="" style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                    value={Email}
                                                    onChange={event => setEmail(event.target.value)} />
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-12">
                                            <fieldset>
                                                <textarea name="message" rows="6" id="message" placeholder="Tulis Pertanyaan"
                                                    required="" style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                    value={Pertanyaan}
                                                    onChange={event => setPertanyaan(event.target.value)}></textarea>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-12">
                                            <fieldset>
                                                <button onClick={()=>submitPertanyaan()} type="submit" id="form-submit" className="main-button">Kirim Pesan</button>
                                            </fieldset>
                                        </div>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                        {/* <!-- ***** Contact Form End ***** --> */}
                        <div className="right-content col-lg-6 col-md-12 col-sm-12">
                            <h2>Kenali <em>Ulemi ID</em></h2>
                            <p>
                                Kami membuka layanan pembuatan undangan digital dalam bentuk website dan video invitation.
                                Undangan digital dapat kamu kirim ke kerabatmu melalui media online seperti sosial media WhatsApp,
                                Instagram dan Media Sosial lainnya. Mudah dan praktis namun tetap terasa elegan dan sopan.
                            </p>
                            <br />
                            <p>
                                Untuk lebih mengenal dan mengatahui apa yang sedang kami kerjakan dan hal-hal lainnya, please follow social media kami dibawah ini.
                            </p>
                            <ul className="social">
                                {/* <li><a href="https://fb.com/templatemo"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                <li><a href="#"><i className="fa fa-rss"></i></a></li>
                                <li><a href="#"><i className="fa fa-dribbble"></i></a></li> */}
                                <li style={{ width: 80, textAlign: 'center' }}>
                                    <a href="https://www.instagram.com/ulemi_id/"><i className="fa fa-instagram"> </i></a>
                                </li>
                            </ul>
                            <a href="https://www.instagram.com/ulemi_id/">@ulemi_id</a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sub-footer">
                            <p>Copyright &copy; 2021 Ulemi ID
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Header;