import { FeaturesIcon1, FeaturesIcon2, FeaturesIcon3 } from '../../../assets'
import React, { useEffect, useState } from 'react'
import { formatNominal,number_format,FormatNumberBy3 } from '../../../utils/functions';
import { useHistory } from 'react-router-dom';


const PriceList = ({Diskon}) => {
    const history = useHistory();
    const [OriginalPrice1,setOriginalPrice1] = useState(110000);
    const [OriginalPrice2,setOriginalPrice2] = useState(180000);
    const [OriginalPrice3,setOriginalPrice3] = useState(230000);
    
    const [DiskonPrice1,setDiskonPrice1] = useState(0);
    const [DiskonPrice2,setDiskonPrice2] = useState(0);
    const [DiskonPrice3,setDiskonPrice3] = useState(0);

    useEffect(()=>{
        setDiskonPrice1(OriginalPrice1-(Diskon/100*OriginalPrice1))
        setDiskonPrice2(OriginalPrice2-(Diskon/100*OriginalPrice2))
        setDiskonPrice3(OriginalPrice3-(Diskon/100*OriginalPrice3))
    },[])


    return (
        <section className="section" id="layanan">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                        data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                        <div className="features-item">
                            <div className="features-icon">
                                <h2>01</h2>
                                <img src={FeaturesIcon1} alt="" />
                                <h4>Undangan Video</h4>
                                <p>Undangan Pernikahan Berupa Video. Dapat kamu sebar melalui chat feed sosial media atau chat pribadi.</p>
                                <br />
                                <ul style={{
                                    textAlign: 'left', listStyle: 'circle', fontSize: 15,
                                    color: '#888',
                                    paddingLeft:10
                                }}>
                                    <li style={{ listStyle: 'circle'}}>Revisi sampai sesuai keinginan kamu.</li>
                                </ul>
                                <br />
                                <h4 style={{ fontSize: 30, color: 'red' }}>Diskon {Diskon}%</h4>
                                <h4 style={{ textDecoration: 'line-through', marginBottom: 5 }}>Rp. {FormatNumberBy3(OriginalPrice1)}</h4>
                                <h4 style={{ fontSize: 30, color: '#fba70b' }}>Rp. {FormatNumberBy3(DiskonPrice1)}</h4>
                                <a href="#" onClick={()=>history.push('/detail-paket',{
                                    Harga:DiskonPrice1,
                                    Diskon:Diskon,
                                    NamaPaket:"Undangan Video",
                                })} className="main-button">
                                    Oke, Saya Mau
                                </a>
                            </div>
                        </div>
                    </div>
          
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                        data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s">
                        <div className="features-item">
                            <div className="features-icon">
                                <h2>02</h2>
                                <img src={FeaturesIcon2} alt="" />
                                <h4>Undangan Website</h4>
                                <p>
                                    Undangan Berupa Halaman Website. Link Undangan dapat kamu sebarkan melalui feed sosial media atau chat personal.
                                </p>
                                <br/>
                                <ul style={{
                                    textAlign: 'left', listStyle: 'circle', fontSize: 15,
                                    color: '#888',
                                    paddingLeft:10
                                }}>
                                    <li style={{ listStyle: 'circle'}}>Halaman Website dengan nama mempelai. Contoh : https://ulemi.id/putra&putri</li>
                                    <li style={{ listStyle: 'circle'}}>Masa Aktif : 6 Bulan</li>
                                    <li style={{ listStyle: 'circle'}}>Bisa dicantumkan nama penerima undangan.</li>
                                    <li style={{ listStyle: 'circle'}}>Ada fitur kirim doa dan ucapan oleh tamu undangan.</li>
                                    <li style={{ listStyle: 'circle'}}>Boleh custom  dan revisi sampai sesuai keinginan kamu.</li>
                                </ul>
                                <br />
                                <h4 style={{ fontSize: 30, color: 'red' }}>Diskon {Diskon}%</h4>
                                <h4 style={{ textDecoration: 'line-through', marginBottom: 5 }}>Rp. {FormatNumberBy3(OriginalPrice2)}</h4>
                                <h4 style={{ fontSize: 30, color: '#fba70b' }}>Rp. {FormatNumberBy3(DiskonPrice2)}</h4>
                                <a href="#" onClick={()=>history.push('/detail-paket',{
                                    Harga:DiskonPrice2,
                                    Diskon:Diskon,
                                    NamaPaket:"Undangan Website",
                                })} className="main-button">
                                    Oke, Saya Mau
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                        data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                        <div className="features-item">
                            <div className="features-icon">
                                <h2>03</h2>
                                <img src={FeaturesIcon3} alt="" />
                                <h4 style={{ fontSize: 30, color: 'red',marginBottom:2 }}>Best Deal</h4>
                                <h4>Paket Komplit</h4>
                                <p>
                                    Undangan Berupa Halaman Website dan Video. Dengan membeli Paket Komplit anda mendapat harga yang lebih murah.
                                </p>
                                {/* <br/>
                                <ul style={{
                                    textAlign: 'left', listStyle: 'circle', fontSize: 15,
                                    color: '#888',
                                    paddingLeft:10
                                }}>
                                    <li>Halaman Website dengan nama mempelai. Contoh : https://ulemi.id/putra&putri</li>
                                    <li>Masa Aktif : 6 Bulan</li>
                                    <li>Bisa dicantumkan nama penerima undangan.</li>
                                    <li>Ada fitur kirim doa dan ucapan oleh tamu undangan.</li>
                                    <li>Boleh custom  dan revisi sampai sesuai keinginan kamu.</li>
                                    <li>Undangan Video.</li>
                                </ul> */}
                                <br />
                                <h4 style={{ fontSize: 30, color: 'red' }}>Diskon {Diskon}%</h4>
                                <h4 style={{ textDecoration: 'line-through', marginBottom: 5 }}>Rp. {FormatNumberBy3(OriginalPrice3)}</h4>
                                <h4 style={{ fontSize: 30, color: '#fba70b' }}>Rp. {FormatNumberBy3(DiskonPrice3)}</h4>
                                <a href="#" onClick={()=>history.push('/detail-paket',{
                                    Harga:DiskonPrice3,
                                    Diskon:Diskon,
                                    NamaPaket:"Paket Komplit",
                                })} className="main-button">
                                    Oke, Saya Mau
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default PriceList;