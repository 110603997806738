import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DetailPaket from '../../pages/DetailPaket';
import Home from '../../pages/Home';

const Routes = () => {
    
    return (
       <Router>
           <Switch>
               
               <Route  path='/detail-paket'>
                   <DetailPaket/>
               </Route>
               <Route  path='/'>
                   <Home/>
               </Route>
             
           </Switch>
       </Router>
    )
}

export default Routes;
