
function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    return (false)
}




function FormatNumberBy3(num, decpoint, sep) {
  num = Math.round(num);
  if(num!=null && num != ""){
    if (arguments.length == 2) {
      sep = ".";
    }
    if (arguments.length == 1) {
      sep = ".";
      decpoint = ",";
    }
    
    num = num.toString().replace(/\D+/g, '').replace(/^0+/, '');
    var a = num.split(decpoint);
    var x = a[0];
    var y = a[1];
    var z = "";
    var i = "";
    
    if (typeof(x) != "undefined") {
      for (i=x.length-1;i>=0;i--)
        z += x.charAt(i);
      
      z = z.replace(/(\d{3})/g, "$1" + sep);
      if (z.slice(-sep.length) == sep)
        z = z.slice(0, -sep.length);
      x = "";
      
      for (i=z.length-1;i>=0;i--)
        x += z.charAt(i);
      
      if (typeof(y) != "undefined" && y.length > 0)
        x += decpoint + y;
    }
    return x;
  }else{
    return 0;
  }
  
}

function formatDate(date,format) {
  if(format=="dd MM yyyy"){
    var event = new Date(date);
    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    var d = event.toLocaleString('ind', options);
    return d;
  }else{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
      return [day, month, year].join('/');
  }
  
}


function customDate(oriDateDMY, dateFormat){
  var splitDate = oriDateDMY.split('/')
  var tgl = splitDate[0]
  var bln = splitDate[1]
  var thn = splitDate[2]

  if (dateFormat == "M/D/Y") {
    var newDate = bln + "/" + tgl + "/"+thn;
  } else if (dateFormat == "YMD"){
    var newDate = thn+bln+tgl;
  }
  return newDate;
}

function calculate_age(dob1){
  var today = new Date();
  var from = dob1.split("/");
  var birthDate = new Date(from[2], from[1] - 1, from[0]);
  
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
  {
    age_now = age_now-1;
    return age_now;
  }else{
    return age_now;
  }

}


const dirMap = {
  // greater-than
  gt: { asc: 1, desc: -1 },
  // less-than
  lt: { asc: -1, desc: 1 }
};

const doSort = (A, B, property, direction = 'ASC') => {
  const a = A[ property ];
  const b = B[ property ];

  if (a < b) {
    return dirMap.lt[ direction.toLowerCase() ];
  }
  if (a > b) {
    return dirMap.gt[ direction.toLowerCase() ];
  }
  return 0;
}

const createSorter = (...args) => {
  if (typeof args[0] === 'string') {
    args = [
      {
        direction: args[1],
        property: args[0]
      }
    ];
  }

  return (A, B) => {
    let ret = 0;

    args.some(sorter => {
      const { property, direction = 'ASC' } = sorter;
      const value = doSort(A, B, property, direction);

      if (value === 0) {
        // they are equal, continue to next sorter if any
        return false;
      } else {
        // they are different, stop at current sorter
        ret = value;

        return true;
      }
    })

    return ret;
  }
}


function formatNominal(nominal) {
  //myVar = setTimeout(function(){ alert("Hello"); }, 3000);


	var input = nominal;
    // ngecek pengecualian, misal angka terlalu kecil / di bawah 1000
    if (input < 1) {
      return 0;
    } else if (input< 1000) {
      return input;
    } 
       
  var su = ["","ribu","juta","milyar","trilyun"];
  var arr_pembagi = [1,1000,1000000,1000000000,1000000000000];

  //proses
  var factor = Math.floor((input.length-1)/3);

  var suffix = su[factor];

  var pembagi = arr_pembagi[factor];
  var hasil = number_format(input/pembagi,2,',','');

  return hasil+" "+suffix;
}

function number_format (number, decimals, decPoint, thousandsSep) { 
 number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
 var n = !isFinite(+number) ? 0 : +number
 var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
 var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
 var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
 var s = ''

 var toFixedFix = function (n, prec) {
  var k = Math.pow(10, prec)
  return '' + (Math.round(n * k) / k)
    .toFixed(prec)
 }

 // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
 s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
 if (s[0].length > 3) {
  s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
 }
 if ((s[1] || '').length < prec) {
  s[1] = s[1] || ''
  s[1] += new Array(prec - s[1].length + 1).join('0')
 }

 return s.join(dec)
}


export {
  ValidateEmail,
  FormatNumberBy3,
  formatDate,
  customDate,
  calculate_age,
  createSorter,
  formatNominal,
  number_format
};