




const WelcomeArea = ({Diskon}) => {
    return (
        <div className="welcome-area" id="welcome">

            {/* <!-- ***** Header Text Start ***** --> */}
            <div className="header-text">
                <div className="container">
                    <div className="row">
                        <div className="left-text col-lg-6 col-md-12 col-sm-12 col-xs-12"
                            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                            {/* <h1>Jasa Pembuatan Undangan Pernikahan Digital<em> Yuk Buat</em></h1> */}
                            <h1 style={{fontSize:40,lineHeight:"50px"}}>Sebar Undangan Pernikahanmu Dengan Undangan Digital<em> Diskon {Diskon}%</em></h1>
                            <p>
                                {/* Saatnya beralih ke undangan digital. Ulemi ID siap bantu bagikan kebahagianmu. */}
                                Undangan digital dapat kamu kirim ke kerabatmu melalui media online seperti sosial media WhatsApp, 
                                Instagram dan Media Sosial lainnya. Mudah dan praktis namun tetap terasa elegan dan sopan.
                            </p>
                            <a href="#layanan" className="main-button-slider" style={{cursor:'pointer'}}>Dapatkan Diskon</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ***** Header Text End ***** --> */}
        </div>
    )
}

export default WelcomeArea;