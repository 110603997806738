import '../../App.css';


import { Features, Header, Preload, Promotion, WelcomeArea,Testimoni, Footer, PriceList } from '../../components';
import { useState } from 'react';
import WebsiteInvitation from './WebsiteInvitation';
import Penawaran from './WebsiteInvitation/Penawaran';

function DetailPaket() {

  const [Diskon,setDiskon] = useState(40);

  return (
    <div>
      {/* <Preload /> */}
      <Header />
      {/* <WelcomeArea Diskon={Diskon}/> */}
      {/* <WebsiteInvitation/> */}
      <div style={{height:100}}></div>
      <Penawaran/>
      {/* <Footer /> */}

    </div>
  );
}

export default DetailPaket;
