


import {
    LeftImage,
    AboutIcon1,
    AboutIcon2,
    AboutIcon3
} from '../../../assets'

const Promotion = () => {
    return (
        <div>

            <div style={{marginTop:0}} className="left-image-decor"></div>

            <section className="section" style={{paddingTop:0}} id="promotion">
                <div className="container">
                    <div className="row">
                        <div className="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
                            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                            <img src={LeftImage} className="rounded img-fluid d-block mx-auto" alt="App" />
                        </div>
                        <div className="right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
                            <ul>
                                <li data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                                    <img src={AboutIcon1} alt="" />
                                    <div className="text">
                                        <h4>Manfaatkan Foto Preweding Kamu</h4>
                                        <p>
                                            {/* Dengan fitur gallery dari Ulemi ID tentunya pra wedding kalian bisa diupload dan ditampilkan di website undangan kalian.  */}
                                            Foto foto prewedding kamu akan kami rangkai dengan cantik di dalam Undangan Web dan Undangan Video. 
                                            Sehingga penerima undangan juga dapat merasakan kebahagiaan kamu dengan pasangan.
                                            <br/>
                                            <br/>
                                            Bagi kamu yang tidak ada foto prewedding, jangan khawatir kamu dapat memasukkan foto foto yang kamu punya.
                                        </p>
                                    </div>
                                </li>
                                <li data-scroll-reveal="enter right move 30px over 0.6s after 0.5s">
                                <img src={AboutIcon2} alt="" />
                                    <div className="text">
                                        <h4>Mudah Dikirim / Disebarkan</h4>
                                        <p>
                                            Dibandingkan dengan undangan cetak,
                                            Undangan digital lebih mudah dikirimkan dan cepat sampai ke penerima undangan dimanapun asal memiliki jaringan internet.

                                            <br/>
                                            <br/>
                                            Bagi kamu yang tidak bisa menginggalkan undangan cetak, kamu tetap dapat menggunakan Undangan Digital khusus untuk dikirimkan ke teman teman kamu yang jauh atau sulit diakses dengan undangan cetak.
                                        </p>
                                    </div>
                                </li>
                                <li data-scroll-reveal="enter right move 30px over 0.6s after 0.6s">
                                <img src={AboutIcon3} alt="" />
                                    <div className="text">
                                        <h4>Fleksibel</h4>
                                        <p>Undangan Digital dapat dicustom sesuai keinginan kamu. 
                                            Dimasa pandemi seperti sekarang ini, pemerintah seringkali memberlakukan PPKM yang bisa saja mengganggu acara pernikahan kamu.
                                            <br/>
                                            <br/>
                                            Undangan Digital dengan mudah dapat menyesuaikan tanggal pernikahan jika acara harus diundur.
                                            <br/>
                                            <br/>
                                            Undangan Digital dengan mudah dapat menyesuaikan dengan mencantumkan live streaming Youtube ke dalam Undangan Web jika terjadi pembatasan tamu undangan.
                                            <br/>
                                            <br/>
                                            Undangan Digital dengan mudah dapat menyesuaikan dengan mengganti narasi menjadi sekedar memohon doa dan restu kepada penerima undangan jika tidak boleh menerima tamu.

                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Promotion;