

import {
    LeftImage, TestimoniImage,TestimoniPPPatmi,TestimoniPPInda,TestimoniPPIndra
} from '../../../assets'

const Testimoni = () => {
    return (
        <div>
            <div className="right-image-decor"></div>
            <section className="section" id="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="center-heading">
                                <h2>Pendapat mereka mengenai <em>Ulemi ID</em></h2>
                                <p>Kami selalu berusaha memberikan layanan terbaik terhadap para client kami. Kami terbuka untuk menerima kritik dan saran dari mereka.</p>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 mobile-bottom-fix-big"
                            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
                            >
                            <div className="owl-carousel owl-theme">
                                <div className="item service-item">
                                    <div className="author">
                                        <i><img src={TestimoniPPPatmi} style={{borderRadius:100}} alt="Author One" /></i>
                                    </div>
                                    <div className="testimonial-content">
                                        <ul className="stars">
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                        </ul>
                                        <h4>Patmiyati</h4>
                                        <p>“Kekinian, menarik, sangat kreatif, keren, cocok digunakan pada situasi seperti ini (pandemi). Efektif dan efisien waktu dalam penyebaran undangan. Pokoknya sukses teruss.”</p>
                                        <span>Kab. Semarang</span>
                                    </div>
                                </div>
                                <div className="item service-item">
                                    <div className="author">
                                        <i><img src={TestimoniPPIndra} style={{borderRadius:100}} alt="Second Author" /></i>
                                    </div>
                                    <div className="testimonial-content">
                                        <ul className="stars">
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                        </ul>
                                        <h4>Indra Purnama</h4>
                                        <p>“Hasilnya bagus, paling suka yang Web Invitation sih. Mau rubah apa aja fast respon nya. Untuk bahan evaluasi nya saran aja sih make sure sebelum deal, misalnya tanya tanggal hari nya udah bener bener itu atau ada perubahan gitu. Buat make sure aja sih. Selain itu bagus suka sama hasilnya😇”</p>
                                        <span>Temanggung</span>
                                    </div>
                                </div>
                                <div className="item service-item">
                                    <div className="author">
                                        <i><img src={TestimoniPPInda} style={{borderRadius:100}} alt="Author Third" /></i>
                                    </div>
                                    <div className="testimonial-content">
                                        <ul className="stars">
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                        </ul>
                                        <h4>Inda Widayanti</h4>
                                        <p>“Terimakasih sudah membantu menyebarkan moment kami, walaupun dengan segala keterbatasan kami tetap bisa share kalau kami melangsungkan pemberkatan kamu, teman-teman, kerabat dan keluarga juga bisa ikut mendoakan melalui kolom ucapan. Terimakasih Ulemi_id & team sehat selalu dan tetap berkarya ya”</p>
                                        <span>Jakarta</span>
                                    </div>
                                </div>
                                {/* <div className="item service-item">
                                    <div className="author">
                                        <i><img src={TestimoniImage} alt="Fourth Author" /></i>
                                    </div>
                                    <div className="testimonial-content">
                                        <ul className="stars">
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                            <li><i className="fa fa-star"></i></li>
                                        </ul>
                                        <h4>Sir James</h4>
                                        <p>"Fusce rutrum in dolor sit amet lobortis. Ut at vehicula justo. Donec quam dolor,
                                            congue a fringilla sed, maximus et urna."</p>
                                        <span>New Villager</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimoni;