import logo from './logo.svg';
import './App.css';


import { Features, Header, Preload, Promotion, WelcomeArea,Testimoni, Footer, PriceList } from './components';
import { useState } from 'react';
import Home from './pages/Home';
import Routes from './config/Routes';

function App() {

  const [Diskon,setDiskon] = useState(40);

  return (
    <div>
      <Routes/>

    </div>
  );
}

export default App;
