

import { useHistory } from 'react-router-dom';
const Header = () => {
    const history = useHistory();
    return (
        <header className="header-area header-sticky">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav">
                            {/* <!-- ***** Logo Start ***** --> */}
                            <a onClick={()=>history.push('/')} href="#" className="logo">
                                Ulemi.ID
                            </a>
                            {/* <a onClick={()=>history.push('/')} className="logo">
                                Ulemi.ID
                            </a> */}
                            {/* <!-- ***** Logo End ***** --> */}
                            {/* <!-- ***** Menu Start ***** --> */}
                            <ul className="nav">
                                <li className="scroll-to-section"><a onClick={()=>history.push('/')} href="#welcome" className="menu-item">Home</a></li>
                                <li className="scroll-to-section"><a onClick={()=>history.push('/')} href="#layanan" className="menu-item">Layanan</a></li>
                                <li className="scroll-to-section"><a onClick={()=>history.push('/')} href="#promotion" className="menu-item">Manfaat</a></li>
                                <li className="scroll-to-section"><a onClick={()=>history.push('/')} href="#testimonials" className="menu-item">Testimonials</a>
                                </li>
                                {/* <li className="submenu">
                                    <a href="javascript:;">Drop Down</a>
                                    <ul>
                                        <li><a href="" className="menu-item">About Us</a></li>
                                        <li><a href="" className="menu-item">Features</a></li>
                                        <li><a href="" className="menu-item">FAQ's</a></li>
                                        <li><a href="" className="menu-item">Blog</a></li>
                                    </ul>
                                </li> */}
                                <li className="scroll-to-section"><a onClick={()=>history.push('/')} href="#contact-us" className="menu-item">Contact Us</a></li>
                            </ul>
                            <a className='menu-trigger'>
                                <span>Menu</span>
                            </a>
                            {/* <!-- ***** Menu End ***** --> */}
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;