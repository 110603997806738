


import {
    LeftImage,
    AboutIcon1,
    AboutIcon2,
    AboutIcon3
} from '../../../assets'
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const Penawaran = () => {
    const history = useHistory();
    const location = useLocation();

    const [Nama, setNama] = useState("")
    const [Email, setEmail] = useState("")
    const [Keterangan, setKeterangan] = useState("")
    const [TanggalResepsi, setTanggalResepsi] = useState("")
    const [Harga, setHarga] = useState(0)
    const [Diskon, setDiskon] = useState(0)
    const [NamaPaket, setNamaPaket] = useState("")
    const [ShowTextAlert, setShowTextAlert] = useState(false)
    // const [ShowTextAlert2, setShowTextAlert2] = useState(false)
    const [TextAlert, setTextAlert] = useState("")
    const [TextAlertValidasi, setTextAlertValidasi] = useState("")
    const [ShowTextAlertValidasi, setShowTextAlertValidasi] = useState(false)

    useEffect(()=>{
        if(location.state != "" && location.state!=null){
            setHarga(location.state.Harga)
            setNamaPaket(location.state.NamaPaket)
            setDiskon(location.state.Diskon)
        }
    },[])

    const submitOrder = () => {
        let validasiMessage = "";
        if (Nama == "") {
            validasiMessage = validasiMessage + "Silahkan isi nama anda terlebih dahulu.";
            setShowTextAlertValidasi(true);
            setTextAlertValidasi(validasiMessage);
            return false;
        }
        if (Email == "") {
            validasiMessage = validasiMessage + "Silahkan isi email anda terlebih dahulu.\n";
            setShowTextAlertValidasi(true);
            setTextAlertValidasi(validasiMessage);
            return false;
        }
        // if (Keterangan == "") {
        //     validasiMessage = validasiMessage + "- Silahkan isi pertanyaan terlebih dahulu.\n";
        // }
       

        // if (TanggalResepsi == "") {
        //     validasiMessage = validasiMessage + "- Silahkan isi tanggal resepsi terlebih dahulu.\n";
        // }
        if (validasiMessage != "") {
            // alert(validasiMessage)
            setShowTextAlertValidasi(true);
            setTextAlertValidasi(validasiMessage);
            return false;
        }
        sendWA()
        
       

        // var requestBody = JSON.stringify({
        //     "Nama": Nama,
        //     "EmailWA": Email,
        //     // "TanggalResepsi":"2021-08-16",
        //     "TanggalResepsi": TanggalResepsi,
        //     "Keterangan": Keterangan,
        //     "Harga": Harga,
        //     "NamaPaket": NamaPaket
        // });

        // var url = 'https://ulemi.id/api/RequestOrder.php';
        
        // // setIsLoading(true)
        // fetch(url, {
        //     method: "POST",
        //     body: requestBody,
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        // })
        //     // .then(fetchStatus)
        //     .then(response => response.json())
        //     .then((data) => {
        //         // var errmsg = data.errmsg;
        //         // setIsLoading(false)
        //         if (data.ErrCode == "0") {
        //             // alert("Berhasil kirim pertanyaan")
        //             setShowTextAlert(true)
        //             setTextAlert("Terima kasih, tim kami akan menghubungi anda segera.")

        //         } else {
        //             alert("Gagal kirim order")
        //         }
        //     })
        //     .catch((error) => {
        //         // setIsLoading(false)
        //         alert("Gagal kirim order")
        //     });
    }

    const sendWA = ()=>{
        window.open("https://wa.me/6283866502154?text=Hallo nama saya " + Nama + ", saya ingin pesan Undangan Digital " + NamaPaket + " diskon "+Diskon+"%. Terima kasih.")
    }

    return (
        <div>

            <div className="left-image-decor"></div>

            <section className="section" style={{ paddingTop: 0 }} id="promotion">
                <div className="container">
                    <div className="row">
                        <div className="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
                            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                            <img src={LeftImage} className="rounded img-fluid d-block mx-auto" alt="App" />
                        </div>
                        <div style={{ paddingTop: 50 }}
                            className="right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
                            <div className="contact-form">
                                <SweetAlert
                                    success title="Berhasil Kirim Order"
                                    show={ShowTextAlert}
                                    onConfirm={() => {
                                        setShowTextAlert(false)
                                        setTextAlert(false)
                                        
                                    }}
                                    btnSize="sm"
                                >
                                   <label>{TextAlert}</label>
                                </SweetAlert>
                                <SweetAlert
                                    show={ShowTextAlertValidasi}
                                    onConfirm={() => {
                                        setShowTextAlertValidasi(false)
                                        setTextAlertValidasi("")
                                    }}
                                    onEscapeKey={() => {
                                        setShowTextAlertValidasi(false)
                                        setTextAlertValidasi("")
                                    }}
                                    onOutsideClick={() => {
                                        setShowTextAlertValidasi(false)
                                        setTextAlertValidasi("")
                                    }}
                                    btnSize="sm"
                                >
                                    {() => (
                                        <div>
                                            <p style={{ fontSize: '20px' }}>{TextAlertValidasi}</p>
                                        </div>
                                    )}
                                </SweetAlert>
                                {/* <form id="contact" action="" method="post"> */}
                                <p>Kami akan menghubungi anda melalui WhatsApp untuk memberikan pilihan design sesuai dengan kebutuhan anda.</p>
                                <div style={{height:20}}></div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <fieldset>
                                            <input name="name" type="text" id="name" placeholder="Nama Kamu" required=""
                                                style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                value={Nama}
                                                onChange={event => setNama(event.target.value)}
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <fieldset>
                                            <input name="email" type="text" id="email" placeholder="Nomor WhatsApp"
                                                required="" style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                value={Email}
                                                onChange={event => setEmail(event.target.value)} />
                                        </fieldset>
                                    </div>
                                    {/* <div className="col-md-12 col-sm-12">
                                        <fieldset>
                                            <input name="tgl_resepsi" type="text" id="tgl_resepsi" placeholder="Tanggal Resepsi"
                                                required="" style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                value={TanggalResepsi}
                                                onChange={event => setTanggalResepsi(event.target.value)} />
                                        </fieldset>
                                    </div> */}
                                    {/* <div className="col-md-12 col-sm-12">
                                        <fieldset>
                                            <textarea name="keterangan" rows="4" id="keterangan" placeholder="Tulis Keterangan"
                                                required="" style={{ backgroundColor: 'gba(250,250,250,0.3)' }}
                                                value={Keterangan}
                                                onChange={event => setKeterangan(event.target.value)}
                                            ></textarea>
                                        </fieldset>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <button onClick={() => submitOrder()} type="submit" id="form-submit" className="main-button">Kirimkan Order</button>
                                        </fieldset>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Penawaran;